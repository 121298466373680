import React from "react";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";
import TrackManagementTask from "./TrackManagement";
import { Card } from "antd";
import { useParams } from "react-router-dom";

const TrackManagement = () => {
  const { trackId } = useParams();

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <div className="row mb-0 align-items-center">
              <div className="col-md-6">
                <h1 className="text-2xl font-bold mb-0">Track Management</h1>
              </div>
              <div className="col-md-6">
                <TrackInfoCard trackId={trackId} />
              </div>
            </div>
            <TrackManagementTask />
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

const TrackInfoCard = ({ trackId }) => {
  return (
    <Card className="track-info-card mb-0">
      <TrackManagementTask.TrackInfo trackId={trackId} />
    </Card>
  );
};

export default TrackManagement;
