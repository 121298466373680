import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Select } from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  departmentList,
  employeeDetails,
  trackInitialization,
} from "../../../../helpers/endpoints/api_endpoints";
import { useTrackContext } from "../../../../contexts/TrackContext";

const { Option } = Select;

const TrackInitializationList = () => {
  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [trackName, setTrackName] = useState("");

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);

  // State for filters
  const [addedByFilter, setAddedByFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [isDoneFilter, setIsDoneFilter] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { updateTracks, fetchTracks } = useTrackContext();

  const fetchEmployeeDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeID(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  }, []);

  const fetchDepartments = useCallback(async () => {
    try {
      const response = await axiosInstance.get(departmentList);
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  const fetchTracksData = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        added_by: addedByFilter,
        department: departmentFilter,
        name__icontains: nameFilter,
        is_done: isDoneFilter,
      });
      const response = await axiosInstance.get(
        `${trackInitialization}?${params}`
      );
      setDataSource(response.data);
      updateTracks(response.data);
    } catch (error) {
      console.error("Error fetching tracks:", error);
    } finally {
      setIsLoading(false);
    }
  }, [addedByFilter, departmentFilter, nameFilter, isDoneFilter, updateTracks]);

  useEffect(() => {
    fetchEmployeeDetails();
    fetchDepartments();
    fetchTracksData();
  }, [fetchEmployeeDetails, fetchDepartments, fetchTracksData]);

  const handleClose = useCallback(() => {
    setShow(false);
    setSelectedRecord(null);
    setTrackName("");
    setSelectedDepartment("");
  }, []);

  const handleViewEdit = useCallback((record) => {
    setSelectedRecord(record);
    setTrackName(record.name);
    setSelectedDepartment(record.department);
    setShow(true);
  }, []);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload = {
        department: selectedDepartment,
        added_by: employeeID,
        name: trackName,
      };

      let updatedTrack;
      if (selectedRecord) {
        const response = await axiosInstance.put(
          `${trackInitialization}${selectedRecord.id}/`,
          payload
        );
        updatedTrack = response.data;
      } else {
        const response = await axiosInstance.post(trackInitialization, payload);
        updatedTrack = response.data;
      }

      // Update local state
      setDataSource((prevData) =>
        selectedRecord
          ? prevData.map((item) =>
              item.id === updatedTrack.id ? updatedTrack : item
            )
          : [...prevData, updatedTrack]
      );

      // Update tracks in context
      updateTracks((prevTracks) =>
        selectedRecord
          ? prevTracks.map((item) =>
              item.id === updatedTrack.id ? updatedTrack : item
            )
          : [...prevTracks, updatedTrack]
      );

      handleClose();
      await fetchTracks(); // Fetch all tracks after saving
    } catch (error) {
      console.error("Error saving track:", error);
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedRecord,
    selectedDepartment,
    employeeID,
    trackName,
    updateTracks,
    handleClose,
    fetchTracks,
  ]);

  const handleDelete = useCallback(
    async (recordId) => {
      if (window.confirm("Are you sure you want to delete this track?")) {
        try {
          await axiosInstance.delete(`${trackInitialization}${recordId}/`);

          // Update local state
          setDataSource((prevData) =>
            prevData.filter((item) => item.id !== recordId)
          );

          // Update tracks in context
          updateTracks(dataSource.filter((item) => item.id !== recordId));
        } catch (error) {
          console.error("Error deleting track:", error);
        }
      }
    },
    [updateTracks, dataSource]
  );

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Track Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department_details",
      key: "department",
      render: (department_details) => department_details.name,
    },
    {
      title: "Created By",
      dataIndex: "added_details",
      key: "created_by",
      render: (added_details) => added_details.employee_name,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            variant="info"
            size="sm"
            onClick={() => handleViewEdit(record)}
          >
            View/Edit
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(record.id)}
            style={{ marginLeft: "8px" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="row mb-3" style={{ padding: "2px" }}>
        <div className="col-md-3" style={{ paddingRight: "15px" }}>
          <div className="input-block position-relative">
            <Input
              placeholder="Search by Added By"
              onChange={(e) => setAddedByFilter(e.target.value)}
              onPressEnter={() => fetchTracksData()}
              style={{
                paddingRight: "35px",
                borderRadius: "8px",
                border: "2px solid #28a745",
              }}
              allowClear
            />
            <i
              className="fa fa-search position-absolute"
              style={{
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#28a745",
              }}
            ></i>
          </div>
        </div>
        <div className="col-md-3" style={{ paddingRight: "15px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Department"
            onChange={(value) => setDepartmentFilter(value)}
          >
            <Option value="">All Departments</Option>
            {departments.map((dept) => (
              <Option key={dept.id} value={dept.id}>
                {dept.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3" style={{ paddingRight: "15px" }}>
          <div className="input-block position-relative">
            <Input
              placeholder="Search by Track Name"
              onChange={(e) => setNameFilter(e.target.value)}
              onPressEnter={() => fetchTracksData()}
              style={{
                paddingRight: "35px",
                borderRadius: "8px",
                border: "2px solid #28a745",
              }}
              allowClear
            />
            <i
              className="fa fa-search position-absolute"
              style={{
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#28a745",
              }}
            ></i>
          </div>
        </div>
        <div className="col-md-3">
          <Select
            style={{ width: "100%" }}
            placeholder="Filter by Status"
            onChange={(value) => setIsDoneFilter(value)}
          >
            <Option value="">All</Option>
            <Option value="true">Done</Option>
            <Option value="false">Not Done</Option>
          </Select>
        </div>
      </div>
      <div className="row mb-10">
        <div className="col-12">
          <div
            className="position-absolute"
            style={{ top: "30px", right: "20px" }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                setShow(true);
                setSelectedRecord(null);
                setTrackName("");
                setSelectedDepartment("");
              }}
            >
              Add Track
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={dataSource}
            rowKey={(record) => record.id}
            loading={isLoading}
          />
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedRecord ? "View/Edit Track" : "Add Track"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Track Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter track name"
                  value={trackName}
                  onChange={(e) => setTrackName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                >
                  <option value="">Select a department</option>
                  {departments.map((dept) => (
                    <option key={dept.id} value={dept.id}>
                      {dept.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedRecord && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedRecord.added_details.employee_name}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Creation Date</Form.Label>
                    <Form.Control
                      type="text"
                      value={new Date(
                        selectedRecord.created_at
                      ).toLocaleString()}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Last Updated</Form.Label>
                    <Form.Control
                      type="text"
                      value={new Date(
                        selectedRecord.updated_at
                      ).toLocaleString()}
                      readOnly
                    />
                  </Form.Group>
                </>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TrackInitializationList;
