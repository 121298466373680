export const OperationsideData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Operations Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/operations-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Track Management",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder-open",
        subMenus: [
          {
            menuValue: "GACL",
            route: "#",
          },
          {
            menuValue: "Customer Journey",
            route: "#",
          },
          {
            menuValue: "Dubai Administration",
            route: "#",
          },
        ],
      },

      {
        menuValue: "Tracks",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Track Initialization",
            route: "/track-initialization",
          },
          {
            menuValue: "Job Assigned",
            route: "/predecessor-assignee",
          },
          {
            menuValue: "End Document",
            route: "/end-document-list",
          },
        ],
      },
    ],
  },
];
