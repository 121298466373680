import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OperationsideData } from "./OperationsideData";
import Scrollbars from "react-custom-scrollbars-2";
import { useTrackContext } from "../../contexts/TrackContext";
import { Spin } from "antd";

const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0",
  minHeight: "200px", // Adjust this value as needed
};

const OperationSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const { t } = useTranslation();
  const { tracks, isLoading, fetchTracks } = useTrackContext(); // Add isLoading from context

  const [sidebarData, setSidebarData] = useState(OperationsideData);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMiniSidebar(document.body.classList.contains("mini-sidebar"));
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  useEffect(() => {
    if (tracks.length === 0) {
      fetchTracks();
    }
  }, [tracks, fetchTracks]);

  const updatedSidebarData = useMemo(() => {
    return OperationsideData.map((section) => {
      if (section.tittle === "MAIN") {
        const updatedMenu = section.menu.map((menuItem) => {
          if (menuItem.menuValue === "Track Management") {
            return {
              ...menuItem,
              subMenus: tracks.map((track) => ({
                menuValue: track.name,
                route: `/track-management/${track.id}`,
                onClick: () => navigate(`/track-management/${track.id}`),
              })),
            };
          }
          return menuItem;
        });
        return { ...section, menu: updatedMenu };
      }
      return section;
    });
  }, [tracks, navigate]);

  useEffect(() => {
    setSidebarData(updatedSidebarData);
  }, [updatedSidebarData]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };

  const expandSubMenus = (menu) => {
    const updatedSidebarData = sidebarData.map((section) => {
      if (section.tittle === "MAIN") {
        const updatedMenu = section.menu.map((menuItem) =>
          menuItem.menuValue === menu.menuValue
            ? { ...menuItem, showSubRoute: !menuItem.showSubRoute }
            : { ...menuItem, showSubRoute: false }
        );
        return { ...section, menu: updatedMenu };
      }
      return section;
    });
    setSidebarData(updatedSidebarData);
  };

  const handleMenuClick = (menu) => {
    if (menu.hasSubRoute) {
      expandSubMenus(menu);
    } else {
      navigate(menu.route);
    }
  };

  return (
    <div
      className="sidebar"
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            {isLoading ? (
              <div style={loaderStyle}>
                <Spin size="default" />
              </div>
            ) : (
              <ul
                className="sidebar-vertical"
                id="vertical-sidebar"
                style={{ display: "block" }}
              >
                {sidebarData.map((mainTitle, index) => (
                  <React.Fragment key={index}>
                    <li className="menu-title">
                      <span>{t(mainTitle.tittle)}</span>
                    </li>
                    {mainTitle.menu.map((menu, menuIndex) => (
                      <li
                        key={menuIndex}
                        className={`${menu.hasSubRoute ? "submenu" : ""} ${
                          pathname === menu.route ? "active" : ""
                        } ${isMiniSidebar ? "mini-sidebar-item" : ""}`}
                      >
                        <Link
                          to={menu.hasSubRoute ? "#" : menu.route}
                          onClick={() => handleMenuClick(menu)}
                          className={`${menu.showSubRoute ? "subdrop" : ""} ${
                            isMiniSidebar && pathname === menu.route
                              ? "mini-sidebar-active"
                              : ""
                          }`}
                        >
                          <i className={menu.icon} />
                          <span>{t(menu.menuValue)}</span>
                          {menu.hasSubRoute && <span className="menu-arrow" />}
                        </Link>
                        {menu.hasSubRoute && (
                          <ul
                            style={{
                              display: menu.showSubRoute ? "block" : "none",
                            }}
                          >
                            {menu.subMenus?.map((subMenu, subMenuIndex) => (
                              <li key={subMenuIndex}>
                                <Link
                                  to={subMenu.route}
                                  onClick={subMenu.onClick}
                                  className={
                                    pathname === subMenu.route ? "active" : ""
                                  }
                                >
                                  {t(subMenu.menuValue)}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </React.Fragment>
                ))}
              </ul>
            )}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default OperationSideBar;
