import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Spin, Typography, Descriptions } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  endDocument,
  endDocumentField,
  predecessorElement,
  predecessorField,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Title, Text } = Typography;

const ApproveJobs = () => {
  const { station, id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);

  const fetchJobDetails = useCallback(async () => {
    try {
      let endpoint;
      switch (station) {
        case "End Document":
          endpoint = endDocument;
          break;
        case "ED Field":
          endpoint = endDocumentField;
          break;
        case "PD Field":
          endpoint = predecessorField;
          break;
        case "PD Element":
          endpoint = predecessorElement;
          break;
        default:
          toast.error("Invalid station");
          return;
      }

      const response = await axiosInstance.get(`${endpoint}${id}/`);
      setJobDetails(response.data);
    } catch (error) {
      console.error("Error fetching job details:", error);
      toast.error("Failed to fetch job details");
    } finally {
      setLoading(false);
    }
  }, [station, id]);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  const handleApprove = async () => {
    setSubmitting(true);
    const toastId = toast.loading("Processing...");
    try {
      const payload = {
        tc_status: true,
        assignee_id: employeeId,
      };
      let endpoint;
      switch (station) {
        case "End Document":
          endpoint = endDocument;
          break;
        case "ED Field":
          endpoint = endDocumentField;
          break;
        case "PD Field":
          endpoint = predecessorField;
          break;
        case "PD Element":
          endpoint = predecessorElement;
          break;
        default:
          toast.error("Invalid station");
          return;
      }
      await axiosInstance.patch(`${endpoint}${id}/`, payload);
      toast.success("Job approved successfully");
      fetchJobDetails();
    } catch (error) {
      console.error("Error approving job:", error);
      toast.error("Failed to approve job");
    } finally {
      setSubmitting(false);
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    fetchJobDetails();
    fetchEmployeeDetails();
  }, [fetchJobDetails]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card>
              <Title level={3}>Approve Job: {station}</Title>
              <Descriptions bordered column={1}>
                {station === "End Document" && (
                  <Descriptions.Item label="End Document Name">
                    <Text strong>{jobDetails?.end_name}</Text>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Track">
                  <Text>{jobDetails?.track_details?.name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Department">
                  <Text>
                    {jobDetails?.track_details?.department_details?.name}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Text
                    type={
                      jobDetails?.status === "Approved" ? "success" : "warning"
                    }
                  >
                    {jobDetails?.status}
                  </Text>
                </Descriptions.Item>
              </Descriptions>

              <Button
                type="primary"
                onClick={handleApprove}
                loading={submitting}
                disabled={jobDetails?.status === "Approved"}
                style={{ marginTop: "20px" }}
              >
                {jobDetails?.status === "Approved"
                  ? "Already Approved"
                  : "Approve Job"}
              </Button>
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default ApproveJobs;
