import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Table, Tabs, Card, Spin, Button } from "antd";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  trackInitialization,
  assignedJob,
} from "../../../../helpers/endpoints/api_endpoints";
import "./TrackManagement.css";

const { TabPane } = Tabs;

const TrackInfo = ({ trackId }) => {
  const [track, setTrack] = useState(null);

  useEffect(() => {
    fetchTrackDetails();
  }, [trackId]);

  const fetchTrackDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${trackInitialization}${trackId}/`
      );
      setTrack(response.data);
    } catch (error) {
      console.error("Error fetching track details:", error);
    }
  };

  return (
    <>
      <h3>Track Name: {track?.name || "Loading..."}</h3>
      <p className="mb-0">
        Department: {track?.department_details?.name || "Loading..."}
      </p>
    </>
  );
};

const TrackManagementTask = () => {
  const { trackId } = useParams();
  const navigate = useNavigate();
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAssignedJobs = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${assignedJob}?track=${trackId}`
      );
      setAssignedJobs(response.data);
    } catch (error) {
      console.error("Error fetching assigned jobs:", error);
      toast.error("Failed to fetch assigned jobs");
    } finally {
      setIsLoading(false);
    }
  }, [trackId]);

  useEffect(() => {
    fetchAssignedJobs();
  }, [fetchAssignedJobs]);

  const handleAcceptJob = useCallback(
    async (jobId) => {
      const toastId = toast.loading("Accepting job...");
      try {
        await axiosInstance.patch(`${assignedJob}${jobId}/`, {
          status: "Accepted",
        });
        toast.update(toastId, {
          render: "Job accepted successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        fetchAssignedJobs(); // Refresh the job list
      } catch (error) {
        console.error("Error accepting job:", error);
        toast.update(toastId, {
          render: "Failed to accept job",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    },
    [fetchAssignedJobs]
  );

  const handleViewJob = useCallback(
    (job) => {
      if (job.task === "TC Assignee") {
        let approveId;
        switch (job.station) {
          case "End Document":
            approveId = job.ed_name;
            break;
          case "ED Field":
            approveId = job.ed_field;
            break;
          case "PD Field":
            approveId = job.pd_field;
            break;
          case "PD Element":
            approveId = job.pd_element;
            break;
          default:
            toast.error("Invalid job configuration");
            return;
        }
        if (approveId) {
          navigate(`/approve-job/${job.station}/${approveId}`);
        } else {
          toast.error("Invalid job configuration");
        }
      } else {
        navigate(`/assigned-jobs/${job.track}`);
      }
    },
    [navigate]
  );

  const columns = [
    {
      title: "Assignee",
      dataIndex: ["assignee_details", "employee_name"],
      key: "assignee",
    },
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
    },
    {
      title: "Track",
      dataIndex: ["track_details", "name"],
      key: "track",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {record.status !== "Accepted" ? (
            <Button type="primary" onClick={() => handleAcceptJob(record.id)}>
              Accept
            </Button>
          ) : (
            <Button type="primary" onClick={() => handleViewJob(record)}>
              View
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="track-management">
      <Tabs defaultActiveKey="1" className="modern-tabs">
        <TabPane tab="Dashboard" key="1">
          <Card className="dashboard-card">
            <p>Dashboard content will be added here in the future.</p>
          </Card>
        </TabPane>
        <TabPane tab="Assigned Jobs" key="2">
          {isLoading ? (
            <div className="loading-spinner">
              <Spin size="large" />
            </div>
          ) : (
            <Card className="assigned-jobs-card">
              <Table
                columns={columns}
                dataSource={assignedJobs}
                rowKey={(record) => record.id}
                pagination={{ pageSize: 10 }}
                scroll={{ x: true }}
              />
            </Card>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

TrackManagementTask.TrackInfo = TrackInfo;

export default TrackManagementTask;
