import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Form, Input, Button, Spin, Typography, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  assignedJob,
  endDocument,
  endDocumentField,
  predecessorElement,
  predecessorField,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Text } = Typography;

const AssignedJobs = () => {
  const { trackId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [endNames, setEndNames] = useState([]);

  const fetchEndNames = useCallback(
    async (job) => {
      if (!job || !job.station) return;

      const endpointMap = {
        "End Document": endDocument,
        "ED Field": endDocumentField,
        "PD Field": predecessorField,
        "PD Element": predecessorElement,
      };

      const endpoint = endpointMap[job.station];
      if (!endpoint) return;

      try {
        const response = await axiosInstance.get(
          `${endpoint}?track=${trackId}`
        );
        if (response.data.length > 0) {
          const fetchedEndNames = response.data.map((item) => ({
            name: item.end_name,
            id: item.id,
          }));
          setEndNames(fetchedEndNames);
        }
      } catch (error) {
        console.error("Error fetching end names:", error);
        toast.error("Failed to fetch end names");
      }
    },
    [trackId]
  );

  const fetchJobDetails = useCallback(async () => {
    if (!employeeId) {
      toast.error("Employee ID is missing");
      return;
    }

    try {
      const response = await axiosInstance.get(
        `${assignedJob}?track=${trackId}&assignee=${employeeId}`
      );
      if (response.data.length > 0) {
        const job = response.data[0];
        setJobDetails(job);
        await fetchEndNames(job);

        if (job.task === "TC Assignee") {
          const stationIdMap = {
            "End Document": job.ed_name,
            "ED Field": job.ed_field,
            "PD Field": job.pd_field,
            "PD Element": job.pd_element,
          };
          const approveId = stationIdMap[job.station];
          if (approveId) {
            navigate(`/approve-job/${job.station}/${approveId}`);
          } else {
            toast.error("Invalid job configuration");
          }
        }
      } else {
        toast.info("No assigned job found for this track");
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
      toast.error("Failed to fetch job details");
    } finally {
      setLoading(false);
    }
  }, [trackId, employeeId, fetchEndNames, navigate]);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  const onFinish = useCallback(
    async (values) => {
      setSubmitting(true);
      const toastId = toast.loading("Processing...");

      const endpointMap = {
        "End Document": endDocument,
        "ED Field": endDocumentField,
        "PD Field": predecessorField,
        "PD Element": predecessorElement,
      };

      const endpoint = endpointMap[jobDetails.station];
      if (!endpoint) {
        toast.error("Invalid station");
        setSubmitting(false);
        toast.dismiss(toastId);
        return;
      }

      try {
        if (jobDetails.task === "Assignee") {
          const payload = {
            track: parseInt(trackId, 10),
            assignee: jobDetails.assignee,
            end_names: values.new_end_names,
          };
          await axiosInstance.post(endpoint, payload);
          toast.success("Job completed successfully");
          fetchJobDetails();
        }
      } catch (error) {
        console.error("Error updating job:", error);
        toast.error("Failed to update job");
      } finally {
        setSubmitting(false);
        toast.dismiss(toastId);
      }
    },
    [jobDetails, trackId, fetchJobDetails]
  );

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  useEffect(() => {
    if (trackId && employeeId) {
      fetchJobDetails();
    }
  }, [trackId, employeeId, fetchJobDetails]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card
              title={`Assigned Job for Track: ${jobDetails?.track_details?.name}`}
            >
              <p>
                Department:{" "}
                {jobDetails?.track_details?.department_details?.name}
              </p>
              <p>Assignee: {jobDetails?.assignee_details?.employee_name}</p>
              <p>
                Station: <Text strong>{jobDetails?.station}</Text>
              </p>

              <div style={{ marginBottom: "20px" }}>
                <Text strong>Existing End Names:</Text>
                <List
                  dataSource={endNames}
                  renderItem={(item) => <List.Item>{item.name}</List.Item>}
                />
              </div>

              {jobDetails?.task === "Assignee" && (
                <Form form={form} onFinish={onFinish} layout="vertical">
                  <Form.List name="new_end_names">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            required={false}
                            key={field.key}
                            label={index === 0 ? "Add New End Names" : ""}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Please input end name or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                style={{ width: "60%" }}
                                placeholder="Enter End Name"
                              />
                            </Form.Item>
                            <Button
                              type="link"
                              onClick={() => remove(field.name)}
                            >
                              Delete
                            </Button>
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add End Name
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                    >
                      Submit New End Names
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default AssignedJobs;
