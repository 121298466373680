import React, { useState, useLayoutEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addPolicy,
  policyFeedback,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Header from "../../../layout/Header";
import { FaCheckDouble, FaFileAlt } from "react-icons/fa";

const PolicyDetails = () => {
  const { id } = useParams();
  const [policy, setPolicy] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [newFeedback, setNewFeedback] = useState("");
  const [employeeDetail, setEmployeeDetails] = useState();
  const [isSideBar, setIsSideBAr] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPolicy, setEditedPolicy] = useState({
    objectives: "",
    guidelines: "",
    value_compatibility: "",
    policy_guidelines: "",
  });

  useLayoutEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeDetails(response.data.employment_details[0].id);
        if (
          response.data.employment_details[0].department_detail.name ===
          "Human Resource"
        ) {
          setIsSideBAr(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    axiosInstance
      .get(`${addPolicy}${id}/`)
      .then((response) => {
        setPolicy(response.data);
        setEditedPolicy({
          objectives: response.data.objectives || "",
          guidelines: response.data.guidelines || "",
          value_compatibility: response.data.value_compatibility || "",
          policy_guidelines: response.data.policy_guidelines || "",
        });
        setFeedbacks(
          response.data.policy_feedback.sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          )
        );
      })
      .catch((error) => {
        toast.error("Failed to fetch policy details or feedback");
      });
  }, [id]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    axiosInstance
      .patch(`${addPolicy}${policy.id}/`, editedPolicy)
      .then(() => {
        setIsEditing(false);
        toast.success("Policy updated successfully!");
        // Fetch the updated policy from the server to refresh the data
        axiosInstance
          .get(`${addPolicy}${id}/`)
          .then((response) => {
            setPolicy(response.data);
            setEditedPolicy({
              objectives: response.data.objectives || "",
              guidelines: response.data.guidelines || "",
              value_compatibility: response.data.value_compatibility || "",
              policy_guidelines: response.data.policy_guidelines || "",
            });
          })
          .catch((error) => {
            toast.error("Failed to refresh policy data.");
          });
      })
      .catch((error) => {
        toast.error("Failed to update policy.");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPolicy({
      ...editedPolicy,
      [name]: value,
    });
  };

  const handleSubmitFeedback = () => {
    if (newFeedback.trim()) {
      const feedbackData = {
        user: employeeDetail,
        policy: policy.id,
        feedback: newFeedback,
        is_read: false,
      };

      axiosInstance
        .post(`${policyFeedback}`, feedbackData)
        .then((response) => {
          setFeedbacks([response.data, ...feedbacks]);
          setNewFeedback("");
          toast.success("Feedback submitted successfully!");
        })
        .catch((error) => {
          toast.error("Failed to submit feedback.");
        });
    }
  };

  const handleMarkAsRead = (feedbackId) => {
    axiosInstance
      .patch(`${policyFeedback}${feedbackId}/`, { is_read: true })
      .then(() => {
        setFeedbacks((prevFeedbacks) =>
          prevFeedbacks.map((feedback) =>
            feedback.id === feedbackId
              ? { ...feedback, is_read: true }
              : feedback
          )
        );
        toast.success("Feedback marked as read!");
      })
      .catch((error) => {
        toast.error("Failed to mark feedback as read.");
      });
  };

  const handleApprove = () => {
    if (policy) {
      const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
      axiosInstance
        .patch(`${addPolicy}${policy.id}/`, {
          is_approved: true,
          approved_date: currentDate,
        })
        .then((response) => {
          toast.success("Policy approved successfully!");
          setPolicy({
            ...policy,
            is_approved: true,
            approved_date: response.data.approved_date,
          });
        })
        .catch((error) => {
          toast.error("Failed to approve policy.");
        });
    }
  };

  const handlePublish = () => {
    if (policy) {
      axiosInstance
        .patch(`${addPolicy}${policy.id}/`, { is_publish: true })
        .then(() => {
          toast.success("Policy published successfully!");
          setPolicy({ ...policy, is_publish: true });
        })
        .catch((error) => {
          toast.error("Failed to publish policy.");
        });
    }
  };

  const openDocument = () => {
    if (policy && policy.document) {
      window.open(policy.document, "_blank");
    } else {
      toast.error("No document available for this policy.");
    }
  };

  if (!policy) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/app/main/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/policies">Policies</Link>
                  </li>
                  <li className="breadcrumb-item active">Policy Details</li>
                </ul>
              </div>
            </div>
          </div>

          {isSideBar && <AdminSideBar />}

          <div className="row">
            <div className="col-sm-12">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h4 className="card-title text-center mb-4">{policy.name}</h4>

                  {/* Add this block for the document button */}
                  {policy.document && (
                    <div className="text-end mb-3">
                      <button
                        className="btn btn-primary"
                        onClick={openDocument}
                      >
                        <FaFileAlt className="me-2" />
                        View Document
                      </button>
                    </div>
                  )}

                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <strong>Objectives: </strong>
                      {isEditing ? (
                        <textarea
                          name="objectives"
                          value={editedPolicy.objectives}
                          onChange={handleInputChange}
                          className="form-control mt-1"
                          style={{ whiteSpace: "pre-wrap" }}
                        />
                      ) : (
                        <div
                          className="mt-1"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {policy.objectives}
                        </div>
                      )}
                    </div>
                    <div className="list-group-item">
                      <strong>Approval Person: </strong>
                      <span className="text-muted">
                        {policy.approval_details?.employee_name}
                      </span>
                    </div>
                    <div className="list-group-item">
                      <strong>Guidelines: </strong>
                      {isEditing ? (
                        <textarea
                          name="guidelines"
                          value={editedPolicy.guidelines}
                          onChange={handleInputChange}
                          className="form-control mt-1"
                          style={{ whiteSpace: "pre-wrap", minHeight: "120px" }}
                        />
                      ) : (
                        <div
                          className="mt-1"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {policy.guidelines}
                        </div>
                      )}
                    </div>
                    <div className="list-group-item">
                      <strong>Compliance Monitored By: </strong>
                      <span className="text-muted">
                        {policy.compliance_monitoring_details
                          ?.map((item) => item.employee_name)
                          .join(", ")}
                      </span>
                    </div>
                    <div className="list-group-item">
                      <strong>Made By Persons: </strong>
                      <span className="text-muted">
                        {policy.made_by_persons_details
                          ?.map((item) => item.employee_name)
                          .join(", ")}
                      </span>
                    </div>
                    <div className="list-group-item">
                      <strong>Valid Until: </strong>
                      <span className="text-muted"> {policy.valid_until}</span>
                    </div>
                    <div className="list-group-item">
                      <strong>Value Compatibility: </strong>
                      {isEditing ? (
                        <textarea
                          name="value_compatibility"
                          value={editedPolicy.value_compatibility}
                          onChange={handleInputChange}
                          className="form-control mt-1"
                          style={{ whiteSpace: "pre-wrap" }}
                        />
                      ) : (
                        <div
                          className="mt-1"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {policy.value_compatibility}
                        </div>
                      )}
                    </div>
                    <div className="list-group-item">
                      <strong>Policy Formulation Guidelines: </strong>
                      {isEditing ? (
                        <textarea
                          name="policy_guidelines"
                          value={editedPolicy.policy_guidelines}
                          onChange={handleInputChange}
                          className="form-control mt-1"
                          style={{ whiteSpace: "pre-wrap", minHeight: "120px" }}
                        />
                      ) : (
                        <div
                          className="mt-1"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {policy.policy_guidelines}
                        </div>
                      )}
                    </div>
                    <div className="list-group-item">
                      <strong>Policy Amendment Date: </strong>
                      <span className="text-muted">
                        {new Date(policy.updated).toLocaleDateString()}
                      </span>
                    </div>
                    {policy.approved_date && (
                      <div className="list-group-item">
                        <strong>Approved Date: </strong>
                        <span className="text-muted">
                          {new Date(policy.approved_date).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                  </div>
                  {isSideBar && (
                    <div className="mt-3 text-center">
                      {isEditing ? (
                        <button
                          className="btn btn-success"
                          onClick={handleSaveClick}
                        >
                          Save Changes
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={handleEditClick}
                        >
                          Edit Policy
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <>
                <h4>Feedbacks</h4>
                <div
                  className="feedback-list mb-2"
                  style={{
                    marginTop: "20px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    paddingRight: "10px",
                    border: "2px solid #e0e0e0",
                    borderRadius: "8px",
                    backgroundColor: "#fafafa",
                    padding: "15px",
                  }}
                >
                  {feedbacks.length > 0 ? (
                    feedbacks.map((feedbackItem) => (
                      <div
                        key={feedbackItem.id}
                        className="feedback-item"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "15px",
                          padding: "15px",
                          borderRadius: "12px",
                          backgroundColor: "#f0f0f5",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          borderLeft: "6px solid #007bff",
                          borderTop: "4px solid #007bff",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                          }}
                        >
                          <strong>
                            {feedbackItem.user_details?.employee_name}
                          </strong>
                          <span style={{ fontSize: "12px", color: "#888" }}>
                            {new Date(feedbackItem.created).toLocaleString()}
                          </span>
                        </div>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "14px",
                            lineHeight: "1.6",
                            color: "#333",
                          }}
                        >
                          {feedbackItem.feedback}
                        </p>

                        {feedbackItem.is_read ? (
                          <FaCheckDouble
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              color: "#28a745",
                            }}
                          />
                        ) : (
                          <button
                            className="btn btn-link"
                            onClick={() => handleMarkAsRead(feedbackItem.id)}
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              color: "#007bff",
                              fontSize: "12px",
                            }}
                          >
                            Mark as Read
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No feedback available.</p>
                  )}
                </div>

                <h4>Leave Feedback</h4>
                <textarea
                  className="form-control"
                  rows={4}
                  value={newFeedback}
                  onChange={(e) => setNewFeedback(e.target.value)}
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                  }}
                />
                <button
                  className="btn btn-primary"
                  onClick={handleSubmitFeedback}
                  style={{
                    backgroundColor: "#007bff",
                    borderColor: "#007bff",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    marginRight: "20px",
                  }}
                >
                  Submit Feedback
                </button>
              </>

              {!isSideBar && !policy.is_approved && (
                <button
                  className="btn btn-success"
                  onClick={handleApprove}
                  style={{
                    backgroundColor: "#28a745",
                    borderColor: "#28a745",
                    borderRadius: "8px",
                    padding: "8px 16px",
                  }}
                >
                  Approve Policy
                </button>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 10,
                }}
              >
                {isSideBar && !policy.is_approved && (
                  <>
                    <button
                      className="btn btn-success"
                      onClick={handleApprove}
                      style={{
                        backgroundColor: "#28a745",
                        borderColor: "#28a745",
                        borderRadius: "8px",
                        padding: "8px 16px",
                        marginRight: "8px", // Spacing between button and label
                      }}
                    >
                      Approve Policy
                    </button>
                    <span style={{ fontSize: "14px", color: "#6c757d" }}>
                      *If already approved click to approve
                    </span>
                  </>
                )}
              </div>
              {isSideBar && policy.is_approved && !policy.is_publish && (
                <button
                  className="btn btn-success"
                  onClick={handlePublish}
                  style={{
                    backgroundColor: "#28a745",
                    borderColor: "#28a745",
                    borderRadius: "8px",
                    padding: "8px 16px",
                  }}
                >
                  Publish
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyDetails;
