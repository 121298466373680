import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { addPolicy } from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import "react-toastify/dist/ReactToastify.css";

const EmployeePolicy = () => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const fetchPolicies = (query = "") => {
    setIsLoading(true);
    axiosInstance
      .get(`${addPolicy}?is_publish=true&name=${query}`)
      .then((response) => {
        const publishedPolicies = response.data.map((policy) => ({
          id: policy.id,
          policyName: policy.name,
          objectives: policy.objectives,
          approvalPersonName: policy.approval_details?.employee_name || "N/A",
          guidelines: policy.guidelines,
          complianceMonitoredBy: policy.compliance_monitoring_details
            ?.map((item) => item.employee_name)
            .join(", "),
          madeByPersons: policy.made_by_persons_details
            ?.map((item) => item.employee_name)
            .join(", "),
          validUntil: policy.valid_until,
          valueCompatibility: policy.value_compatibility,
          updated: new Date(policy.updated).toLocaleDateString(),
          approved_date: new Date(policy.approved_date).toLocaleDateString(),
          policy_guidelines: policy.policy_guidelines,
          document: policy.document,
        }));
        setPolicies(publishedPolicies);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching policies", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value);
    fetchPolicies(value);
  };

  const handleView = (policy) => {
    navigate(`/employee-policy-details/${policy.id}`);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Policy Name",
      dataIndex: "policyName",
      key: "policyName",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Policy Ammendment Date",
      dataIndex: "updated",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Approved Date",
      dataIndex: "approved_date",
      sorter: (a, b) => new Date(a.approved_date) - new Date(b.approved_date),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleView(record)}
        >
          <i className="fa fa-eye m-r-5" /> View
        </button>
      ),
    },
  ];

  return (
    <>
      <Header />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row align-items-center mb-3">
            <div className="col-md-8">
              <Breadcrumbs
                maintitle="Policies"
                title="Employee Policies"
                subtitle="Published"
                name="Published Policies"
              />
            </div>
            <div className="col-md-4 text-end">
              <div className="input-block position-relative">
                <Input
                  type="text"
                  placeholder="Search by Policy Name"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{
                    paddingRight: "35px",
                    borderRadius: "8px",
                    border: "2px solid #28a745",
                  }}
                  allowClear
                />
                <i
                  className="fa fa-search position-absolute"
                  style={{
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#28a745",
                  }}
                ></i>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={policies}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePolicy;
